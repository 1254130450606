import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';

export const Text = props => {
  const theme = useTheme();
  const {children, variant, element, colour, muiOverrides, weight, size, uppercase, className, align} = props;

  let propStyles = null;

  if (size === 'extraLarge' && variant === 'h1') {
    propStyles = {
      fontSize: '28px;',
      // letterSpacing: '7px',
      [theme.breakpoints.up('md')]: {
        fontSize: '60px',
        lineHeight: '1.06',
        // letterSpacing: '11px'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '80px',
        // letterSpacing: '20px'
      }
    }
  }

  if (size === 'extraLargeLargeOnly' && variant === 'h1') {
    propStyles = {
      fontSize: '28px;',
      // letterSpacing: '7px',
      [theme.breakpoints.up('lg')]: {
        fontSize: '80px',
        lineHeight: '1.06',
        // letterSpacing: '20px'
      }
    }
  }

  if (uppercase && variant === 'h4') {
    propStyles = {
      // letterSpacing: '4.5px',
      // [theme.breakpoints.up('md')]: {
      //   letterSpacing: '8px'
      // }
    }
  }
  if (uppercase && (variant === 'h5' || variant === 'h6')) {
    propStyles = {
      //letterSpacing: '4px',
    }
  }

  if (variant === 'subTitle' || variant === 'subtitle1' || variant === 'subtitle2') {
    propStyles = {
      color: theme.palette[colour],
      fontWeight: weight ? weight : 700,
      letterSpacing: 1,
      fontSize: 12,
      textTransform: 'uppercase',
      marginBottom: 8,

      [theme.breakpoints.up('md')]: {
        //letterSpacing: 2,
        fontSize: 14,
      }
    }
  }

  const useStyles = makeStyles({
    root: {
      color: theme.palette[colour],
      fontWeight: weight ? weight : null,
      textTransform: uppercase ? 'uppercase' : 'none',
      ...propStyles
    }
  });
  const styles = useStyles();

  return (
    <Typography align={align} classes={styles} className={className} variant={variant} component={element ? element : undefined} {...muiOverrides}>
      {children}
    </Typography>
  );
};

Text.propTypes = {
  /** Styling to apply */
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'subTitle', 'subtitle1', 'subtitle2']),
  /** Change the semantic element applied. If unset it will default to style (body1 and body2 default to p) */
  element: PropTypes.string,
  /** Weight for element */
  weight: PropTypes.oneOf([300, 400, 600, 700, 800]),
  /** Side for element */
  size: PropTypes.oneOf(['extraLarge', 'large', 'extraLargeLargeOnly']),
  /** Side for element */
  uppercase: PropTypes.bool,
  /** Colour for element */
  colour: PropTypes.oneOf(['black', 'white', 'charcoal', 'middleGrey', 'inherit', 'vividBlue', 'skyBlue', 'error']),
  /** Override props of the MUI Typography component see: https://material-ui.com/api/typography/ */
  muiOverrides: PropTypes.object,
  /** For styled components */
  className: PropTypes.string,
  /** text align for element */
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

Text.defaultProps = {
  style: 'body1',
  colour: 'inherit',
  weight: null, // Don't set so we can use theme defaults,
  size: null,
  uppercase: false,
  className: '',
  align: 'left'
};

export default Text